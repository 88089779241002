





























































































































































































































































































































































.advisoryNotice /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.advisoryNotice {
  .el-form {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 0;
    span {
      color: #909399;
      font-size: 12px;
    }
  }
}
